// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-definition-page-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/components/definition-page.js" /* webpackChunkName: "component---src-components-definition-page-js" */),
  "component---src-components-resource-post-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/components/resource-post.js" /* webpackChunkName: "component---src-components-resource-post-js" */),
  "component---src-pages-404-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-games-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-getting-started-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/games/getting-started.js" /* webpackChunkName: "component---src-pages-games-getting-started-js" */),
  "component---src-pages-index-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-ti-83-plus-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-83-plus.js" /* webpackChunkName: "component---src-pages-ti-83-plus-js" */),
  "component---src-pages-ti-83-plus-starters-guide-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-83-plus/starters-guide.js" /* webpackChunkName: "component---src-pages-ti-83-plus-starters-guide-js" */),
  "component---src-pages-ti-83-plus-where-to-find-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-83-plus/where-to-find.js" /* webpackChunkName: "component---src-pages-ti-83-plus-where-to-find-js" */),
  "component---src-pages-ti-84-ce-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-84-ce.js" /* webpackChunkName: "component---src-pages-ti-84-ce-js" */),
  "component---src-pages-ti-84-ce-starters-guide-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-84-ce/starters-guide.js" /* webpackChunkName: "component---src-pages-ti-84-ce-starters-guide-js" */),
  "component---src-pages-ti-84-ce-where-to-find-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-84-ce/where-to-find.js" /* webpackChunkName: "component---src-pages-ti-84-ce-where-to-find-js" */),
  "component---src-pages-ti-84-plus-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-84-plus.js" /* webpackChunkName: "component---src-pages-ti-84-plus-js" */),
  "component---src-pages-ti-84-plus-starters-guide-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-84-plus/starters-guide.js" /* webpackChunkName: "component---src-pages-ti-84-plus-starters-guide-js" */),
  "component---src-pages-ti-84-plus-where-to-find-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-84-plus/where-to-find.js" /* webpackChunkName: "component---src-pages-ti-84-plus-where-to-find-js" */),
  "component---src-pages-ti-89-titanium-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-89-titanium.js" /* webpackChunkName: "component---src-pages-ti-89-titanium-js" */),
  "component---src-pages-ti-89-titanium-starters-guide-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-89-titanium/starters-guide.js" /* webpackChunkName: "component---src-pages-ti-89-titanium-starters-guide-js" */),
  "component---src-pages-ti-89-titanium-where-to-find-js": () => import("/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/src/pages/ti-89-titanium/where-to-find.js" /* webpackChunkName: "component---src-pages-ti-89-titanium-where-to-find-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/.cache/data.json")

