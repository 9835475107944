module.exports = [{
      plugin: require('/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-61451252-2","head":true},
    },{
      plugin: require('/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography.js"},
    },{
      plugin: require('/Users/bryantran/Desktop/websites/infinitycalcs/InfinityCalcs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
